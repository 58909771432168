.fade-in {
    animation: fadeInAnimation ease 1.5s;
    -webkit-animation: fadeInAnimation ease 1.5s;
    -moz-animation: fadeInAnimation ease 1.5s;
    -o-animation: fadeInAnimation ease 1.5s;
    -ms-animation: fadeInAnimation ease 1.5s;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* 这会确保元素的宽度包括其内部边距和边框 */
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.login-input {
    width: 240px;
    height: 36px;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-button {
    width: 70%;
}

.forget-link {
    width: 50%;
    text-align: right;
    left: 20px;
}
.login-form-button:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

.btn-grad:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

.body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url(../../assets/backgrounds/login_background_square.jpg); */
    background-image: url("https://images.unsplash.com/photo-1673427147403-9e87c4fe8a26?q=80&w=2728&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    position: relative;
}
.container {
    /* background-color: #ffffff; */
    border-radius: 10px;
    box-shadow: 0 calc(0.2 * 480px) calc(0.5 * 480px) hsla(0, 0%, 0%, 0.1); /* 水平偏移，垂直偏移，模糊半径，阴影颜色 */
    width: 768px;
    max-width: 100%;
    min-height: 480px;
    display: flex;
}

.form-container {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    flex-direction: column;
    background-color: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.left-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 38px;
    height: 38px;
}

.left-container {
    width: 50%;
    background-color: #b3c8e106;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    color: #1b365c;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.right-welcome-title {
    letter-spacing: 2px;
    font-weight: 400;
}

.left-welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    width: 100%;
}

.left-welcome-container > * {
    margin-top: 1;
    margin-bottom: 10px;
    line-height: 1;
    color: #f4f4f4;
}

.left-welcome-title {
    letter-spacing: 2px;
    font-weight: 400;
    margin-bottom: 20px;
}

.left-welcome-content {
    font-size: 20px;
    font-weight: 400;
    width: 80%;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 1px;
}
